import React, { memo, Fragment } from "react";

// react-bootstrap
import { Col, Row, Container, Card } from "react-bootstrap";
import Team from "../components/widgets/team";
import BlogWidget from "../components/widgets/blog";
import Client from "../components/widgets/client";
import Testiominal from "../components/widgets/testiominal";

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import image from "../../../../assets/modules/landing-pages/images/home-1/banner-top.webp";
import image from "../../../../assets/modules/landing-pages/images/home-1/banner-top-pangea-mobile-new.webp";
import image1 from "../../../../assets/modules/landing-pages/images/home-1/aboutus.webp";
import image2 from "../../../../assets/modules/landing-pages/images/home-1/playstore.webp";
import image3 from "../../../../assets/modules/landing-pages/images/home-1/appstore.webp";
import image4 from "../../../../assets/modules/landing-pages/images/home-1/dwn-1.webp";
import image6 from "../../../../assets/modules/landing-pages/images/home-1/01.webp";
import image7 from "../../../../assets/modules/landing-pages/images/home-1/02.webp";
import image8 from "../../../../assets/modules/landing-pages/images/home-1/03.webp";
import image9 from "../../../../assets/modules/landing-pages/images/home-1/team1.webp";
import image10 from "../../../../assets/modules/landing-pages/images/home-1/team2.webp";
import image11 from "../../../../assets/modules/landing-pages/images/home-1/team-3.webp";
import image12 from "../../../../assets/modules/landing-pages/images/home-1/team-4.webp";
import image13 from "../../../../assets/modules/landing-pages/images/home-1/04.webp";
import image14 from "../../../../assets/modules/landing-pages/images/home-1/05.webp";
import image15 from "../../../../assets/modules/landing-pages/images/home-1/06.webp";
import image16 from "../../../../assets/modules/landing-pages/images/home-1/07.webp";
import image17 from "../../../../assets/modules/landing-pages/images/home-1/08.webp";
import image18 from "../../../../assets/modules/landing-pages/images/home-1/09.webp";
import image19 from "../../../../assets/modules/landing-pages/images/home-1/10.webp";
import image20 from "../../../../assets/modules/landing-pages/images/home-1/11.webp";
import image21 from "../../../../assets/modules/landing-pages/images/home-1/13.webp";
import image22 from "../../../../assets/modules/landing-pages/images/home-1/15.webp";
import image23 from "../../../../assets/modules/landing-pages/images/home-1/16.webp";
import image24 from "../../../../assets/modules/landing-pages/images/home-1/14.webp";
import image25 from "../../../../assets/modules/landing-pages/images/home-1/17.webp";
import image26 from "../../../../assets/modules/landing-pages/images/home-1/18.webp";


// // Placeholder function to generate a placeholder image URL
// const getPlaceholderImage = (width = 600, height = 400) => {
//   return `https://via.placeholder.com/${width}x${height}`;
// };

//Count-up
import CountUp from "react-countup";

// react-router
import { Link } from "react-router-dom";

const extraContentPangea = 

 
<main className="container body-container-wrapper" 
// style={{ marginTop: "123px" }}
>
    

<div className="container-fluid body-container">
<div className="row-fluid-wrapper">
<div className="row-fluid">
<div className="span12 widget-span widget-type-cell "  data-widget-type="cell" data-x="0" data-w="12">

<div className="row-fluid-wrapper row-depth-1 row-number-1 dnd_area-row-0-max-width-section-centering dnd_area-row-0-vertical-alignment dnd-section dnd_area-row-0-padding">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell dnd-column dnd_area-column-1-vertical-alignment"  data-widget-type="cell" data-x="0" data-w="12">

<div className="row-fluid-wrapper row-depth-1 row-number-2 dnd_area-column-1-row-0-padding dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module dnd_area-module-2-flexbox-positioning"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-2" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_dnd_area-module-2_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><div style={{ textAlign: "center" }}>
<h1>A.I. for Creativity and Open Minds</h1>
<p>Our A.I. powered writing editor helps you create and curate more inspiring content</p>
</div></span></div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-3 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-3" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"  data-hs-cos-general-type="widget" data-hs-cos-type="module">

  






<div className="button--center">
<a href="https://www.pangeasocial.com/pangea-social-beta-tester-registration?hsLang=en" className="button button--icon-right" id="button_dnd_area-module-3" rel="">

  
Sign up

</a>
</div></div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-4 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_widget_1688357489809" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"  data-hs-cos-general-type="widget" data-hs-cos-type="module">

  
    <div className="oembed_container oembed_container--full-size" id="oembed_container-widget_1688357489809">

      

      
      
      
{/* 
      
        <div className="iframe_wrapper" data-embed-response-html="<iframe width=&quot;200&quot; height=&quot;113&quot; src=&quot;https://www.youtube.com/embed/Gxt0pt4FpYo?feature=oembed&quot; frameBorder=&quot;0&quot; allow=&quot;accelerometer; autoPlay; clipboardWrite; encrypted-media; gyroscope; picture-in-picture; web-share&quot; allowFullScreen title=&quot;Pangea Social  -- A.I. for creativity and open minds&quot;></iframe>" data-embed-response-width="200" data-embed-response-height="113" data-embed-url="https://youtu.be/Gxt0pt4FpYo">
        <iframe width="200" height="113" src="https://www.youtube.com/embed/Gxt0pt4FpYo?feature=oembed" frameBorder="0" allow="accelerometer; autoPlay; clipboardWrite; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen="" title="undefined" className="oembed_container_iframe"></iframe></div>
       */}
<div className="iframe_wrapper" data-embed-response-html="<iframe width=&quot;200&quot; height=&quot;113&quot; src=&quot;https://www.youtube.com/embed/Gxt0pt4FpYo?feature=oembed&quot; frameborder=&quot;0&quot; allow=&quot;accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share&quot; allowfullscreen title=&quot;Pangea Social  -- A.I. for creativity and open minds&quot;></iframe>" data-embed-response-width="200" data-embed-response-height="113" data-embed-url="https://youtu.be/Gxt0pt4FpYo">
          <iframe width="960" height="565" src="https://www.youtube.com/embed/Gxt0pt4FpYo?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="" title="undefined" className="oembed_container_iframe"></iframe></div>
    </div>
  



</div>

</div>
</div>
</div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-5 dnd_area-row-1-padding dnd_area-row-1-vertical-alignment dnd-section">
<div className="row-fluid ">
<div className="span6 widget-span widget-type-cell dnd_area-column-4-vertical-alignment dnd_area-column-4-padding dnd-column"  data-widget-type="cell" data-x="0" data-w="6">

<div className="row-fluid-wrapper row-depth-1 row-number-6 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-5" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_dnd_area-module-5_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h2>Constructive Online Communities Build Stronger Social Impact Movements</h2>
<p>Pangea Social's vision is to create inclusive and safe online communities that can be used to drive a positive impact on our physical global and local communities.</p></span></div>

</div>
</div>
</div>

</div>
<div className="span5 widget-span widget-type-cell dnd_area-column-7-vertical-alignment dnd-column"  data-widget-type="cell" data-x="6" data-w="5">

<div className="row-fluid-wrapper row-depth-1 row-number-7 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-8" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-linked_image widget-type-linked_image"  data-hs-cos-general-type="widget" data-hs-cos-type="module">
  










<span id="hs_cos_wrapper_dnd_area-module-8_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_linked_image"  data-hs-cos-general-type="widget" data-hs-cos-type="linked_image"><img src="https://www.pangeasocial.com/hubfs/shutterstock_1719924805%20(1).jpg" className="hs-image-widget " style={{ maxWidth: "100%", height: "auto" }} alt="shutterstock_1719924805 (1)" title="shutterstock_1719924805 (1)" /></span></div>

</div>
</div>
</div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-8 dnd_area-row-2-padding dnd-section dnd_area-row-2-vertical-alignment">
<div className="row-fluid ">
<div className="span6 widget-span widget-type-cell dnd_area-column-22-vertical-alignment dnd_area-column-22-padding dnd-column"  data-widget-type="cell" data-x="0" data-w="6">

<div className="row-fluid-wrapper row-depth-1 row-number-9 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-23" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"  data-hs-cos-general-type="widget" data-hs-cos-type="module">


  

<img className="drop-shadow image-animate aos-init" src="https://www.pangeasocial.com/hubfs/writing%20editor.png" alt="writing editor" style={{ maxWidth: "100%", height: "auto" }} data-aos="fade-up" />
</div>

</div>
</div>
</div>

</div>
<div className="span6 widget-span widget-type-cell dnd_area-column-24-vertical-alignment dnd-column"  data-widget-type="cell" data-x="6" data-w="6">

<div className="row-fluid-wrapper row-depth-1 row-number-10 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-25" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_dnd_area-module-25_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h2>Pangea Social A.I. Teaches Better Writing</h2>
<p style={{ fontSize: "20px" }}><span style={{ color: "#5a5a5a" }}>Pangea Social uses a new type of AI that identifies the parts of your writing that are the most open-minded and helpful, and also the parts that are the most biased or digressed. Then, it gives guidance on what you wrote and a chance to edit it for a higher "Big Mind" score.</span></p></span></div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-11 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-26" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"  data-hs-cos-general-type="widget" data-hs-cos-type="module">

  






<div>
<a href="https://www.pangeasocial.com/pangea-social-beta-tester-registration?hsLang=en" className="button button--icon-right" id="button_dnd_area-module-26" target="_blank" rel="noopener ">

  
Try It

</a>
</div></div>

</div>
</div>
</div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-12 dnd-section dnd_area-row-3-padding dnd_area-row-3-vertical-alignment">
<div className="row-fluid ">
<div className="span6 widget-span widget-type-cell dnd_area-column-17-padding dnd_area-column-17-vertical-alignment dnd-column"  data-widget-type="cell" data-x="0" data-w="6">

<div className="row-fluid-wrapper row-depth-1 row-number-13 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-18" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_dnd_area-module-18_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h2>Discover Your Most Powerful Ideas</h2>
<p>Pangea Social has a unique interface that diagrams your copy and shows you exactly where you are creating touchstone ideas that lead a conversation. This helps participants in social media to discover their own powerful ideas, to remove extraneous and harmful phrases, and to write comments that encourage open-minded and constructive dialogue.</p></span></div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-14 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-19" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"  data-hs-cos-general-type="widget" data-hs-cos-type="module">

  






<div>
<a href="https://www.pangeasocial.com/pangea-social-beta-tester-registration?hsLang=en" className="button button--icon-right" id="button_dnd_area-module-19" target="_blank" rel="noopener ">

  
Try It

</a>
</div></div>

</div>
</div>
</div>

</div>
<div className="span6 widget-span widget-type-cell dnd_area-column-20-vertical-alignment dnd-column"  data-widget-type="cell" data-x="6" data-w="6">

<div className="row-fluid-wrapper row-depth-1 row-number-15 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-21" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"  data-hs-cos-general-type="widget" data-hs-cos-type="module">


  

<img className="drop-shadow image-animate aos-init" src="https://www.pangeasocial.com/hubfs/CognitiveRelativityHeatMap.png" alt="CognitiveRelativityHeatMap" style={{ maxWidth: "100%", height: "auto" }} data-aos="fade-up" />
</div>

</div>
</div>
</div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-16 dnd-section dnd_area-row-4-max-width-section-centering dnd_area-row-4-padding">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell dnd-column"  data-widget-type="cell" data-x="0" data-w="12">

<div className="row-fluid-wrapper row-depth-1 row-number-17 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-28" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_dnd_area-module-28_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><h2 style={{ textAlign: "center" }}>Try our solution for online content curation &amp; conversation optimization</h2></span></div>

</div>
</div>
</div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-18 dnd-section dnd_area-row-5-padding dnd_area-row-5-vertical-alignment">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell dnd_area-column-9-vertical-alignment dnd-column"  data-widget-type="cell" data-x="0" data-w="12">

<div className="row-fluid-wrapper row-depth-1 row-number-19 dnd_area-column-9-row-0-padding dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell dnd-column"  data-widget-type="cell" data-x="0" data-w="12">

<div className="row-fluid-wrapper row-depth-1 row-number-20 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module dnd_area-module-10-flexbox-positioning"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-10" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_dnd_area-module-10_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><div style={{ textAlign: "center" }}>
<h2>Pangea Social's Vision: Give Power to the People</h2>
<p>A social network where creativity is the main source of value creation</p>
</div></span></div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-21 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget widget_1690175187631-flexbox-positioning dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_widget_1690175187631" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-linked_image"  data-hs-cos-general-type="widget" data-hs-cos-type="module">
  










<span id="hs_cos_wrapper_widget_1690175187631_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_linked_image"  data-hs-cos-general-type="widget" data-hs-cos-type="linked_image"><img src="https://www.pangeasocial.com/hubfs/PangeaCircularEconomy.png" className="hs-image-widget " style={{ maxWidth: "100%", height: "auto" }} alt="PangeaCircularEconomy" title="PangeaCircularEconomy" loading="lazy" /></span></div>

</div>
</div>
</div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-22 dnd-row">
<div className="row-fluid ">
<div className="span4 widget-span widget-type-cell dnd-column"  data-widget-type="cell" data-x="0" data-w="4">

<div className="row-fluid-wrapper row-depth-1 row-number-23 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-12" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"  data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="feature-block" style={{ backgroundColor: "rgba(255, 255, 255, 0.0)" }}>
<div className="feature-block__wrapper" style={{ textAlign: "center" }}>
  
    <div className="feature-block__icon-wrapper" style={{ fontSize: "60px" }}>
      <i className="feature-block__icon fa fa-sync-alt" style={{ color: "#1E90FF" }} aria-hidden="true"></i>
    </div>
  
    
  
  <div>
    <h4 className="feature-block__headline">
      A Circular Economy
    </h4>
    <div className="feature-block__text">
      Creators, Commenters, Contracts, Workers, and Investors form a co-op based on creativity
    </div>
  </div>
        
</div>
</div></div>

</div>
</div>
</div>

</div>
<div className="span4 widget-span widget-type-cell dnd-column"  data-widget-type="cell" data-x="4" data-w="4">

<div className="row-fluid-wrapper row-depth-1 row-number-24 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-14" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"  data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="feature-block" style={{ backgroundColor: "rgba(255, 255, 255, 0.0)" }}>
<div className="feature-block__wrapper" style={{ textAlign: "center" }}>
  
    <div className="feature-block__icon-wrapper" style={{ fontSize: "60px" }}>
      <i className="feature-block__icon fa fa-dollar-sign" style={{ color: "#1E90FF" }} aria-hidden="true"></i>
    </div>
  
    
  
  <div>
    <h4 className="feature-block__headline">
       Optimize Content and Social Media Curation
    </h4>
    <div className="feature-block__text">
      Our Solution delivers Quality, Bias, and Virality ratings
    </div>
  </div>
        
</div>
</div></div>

</div>
</div>
</div>

</div>
<div className="span4 widget-span widget-type-cell dnd-column"  data-widget-type="cell" data-x="8" data-w="4">

<div className="row-fluid-wrapper row-depth-1 row-number-25 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-16" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"  data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="feature-block" style={{ backgroundColor: "rgba(255, 255, 255, 0.0)" }}>
<div className="feature-block__wrapper" style={{ textAlign: "center" }}>
  
    <div className="feature-block__icon-wrapper" style={{ fontSize: "60px" }}>
      <i className="feature-block__icon fa fa-users" style={{ color: "#1E90FF" }} aria-hidden="true"></i>
    </div>
  
    
  
  <div>
    <h4 className="feature-block__headline">
      Group Collaboration
    </h4>
    <div className="feature-block__text">
      Activists, writers, academics, and online communities can solve problems better together
    </div>
  </div>
        
</div>
</div></div>

</div>
</div>
</div>

</div>
</div>
</div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-26 dnd_area-row-6-padding dnd_area-row-6-vertical-alignment dnd-section dnd_area-row-6-max-width-section-centering">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell dnd_area-column-43-vertical-alignment dnd-column"  data-widget-type="cell" data-x="0" data-w="12">

<div className="row-fluid-wrapper row-depth-1 row-number-27 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-44" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"  data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="text-center">
<i className="testimonial__icon fa fa-quote-left" aria-hidden="true"></i>
<div className="testimonial__text">
  <div id="hs_cos_wrapper_dnd_area-module-44_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="inline_rich_text" data-hs-cos-field="testimonial_text"><h3><span>"We are working with Pangea Social on applications for one of our dramatic series projects that has a big social impact campaign component. Several of the execs from different orgs were just on a group call and the software kinda blew their minds. The applications could be for anything from serious academic content to burger commercials and more "</span></h3></div>
</div>  
<img className="testimonial__image" src="https://www.pangeasocial.com/hubfs/markwheeler.jpeg" alt="markwheeler" />
<div className="testimonial__name text-bold">
  <div id="hs_cos_wrapper_dnd_area-module-44_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text"  data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="testimonial_name">Mark Wheeler</div>
</div>
<div className="testimonial__title text-small">
  <div id="hs_cos_wrapper_dnd_area-module-44_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_inline_text"  data-hs-cos-general-type="widget" data-hs-cos-type="inline_text" data-hs-cos-field="testimonial_title">Founder | FailsafeDAO</div>
</div>
</div></div>

</div>
</div>
</div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-28 dnd_area-row-7-vertical-alignment dnd-section dnd_area-row-7-max-width-section-centering dnd_area-row-7-padding">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-cell dnd-column dnd_area-column-48-vertical-alignment"  data-widget-type="cell" data-x="0" data-w="12">

<div className="row-fluid-wrapper row-depth-1 row-number-29 dnd_area-column-48-row-0-padding dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd_area-module-49-flexbox-positioning dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-49" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_dnd_area-module-49_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"  data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><div style={{ textAlign: "center" }}>
<h2>Sign Up as a Beta Tester Today</h2>
<p>Pangea Social's A.I.-assisted writing editor is in private beta. Contact us to sign up and give it a try.</p>
</div></span></div>

</div>
</div>
</div>

<div className="row-fluid-wrapper row-depth-1 row-number-30 dnd-row">
<div className="row-fluid ">
<div className="span12 widget-span widget-type-custom_widget dnd-module"  data-widget-type="custom_widget" data-x="0" data-w="12">
<div id="hs_cos_wrapper_dnd_area-module-50" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"  data-hs-cos-general-type="widget" data-hs-cos-type="module">

  






<div className="button--center">
<a href="https://www.pangeasocial.com/pangea-social-beta-tester-registration?hsLang=en" className="button button--icon-right" id="button_dnd_area-module-50" target="_blank" rel="noopener ">

  
Sign Up

</a>
</div></div>

</div>
</div>
</div>

</div>
</div>
</div>

</div>
</div>
</div>
</div>

</main> 



const logoMarquee = <div className="section-padding bg-secondary position-relative">
  <Container className="client-list">
    <ul className="p-0 m-0 d-flex align-items-center gap-3  client-mrquee list-unstyled">
      <li className="highway-car ">
        <Client clientImage={image16} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image17} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image18} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image19} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image20} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image16} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image17} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image18} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image19} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image20} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image16} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image17} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image18} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image19} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image20} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image16} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image17} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image18} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image19} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image20} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image16} />
      </li>
      <li className="highway-car ">
        <Client clientImage={image17} />
      </li>

    </ul>
  </Container>
</div>;
const testimonials = <div className="section-padding">
  <Container>
    <Row className="align-items-center text-center">
      <Col lg={12}>
        <div className="mb-2 text-uppercase text-primary sub-title">Testimony</div>
        <h2 className="text-secondary customer-txt mb-5 text-capitalize">
          What our{" "}
          <span className="text-primary">
            Customer’s
          </span> are saying
        </h2>
      </Col>
      <div
        className="overflow-hidden slider-circle-btn mt-3 swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events" id="testimonial-slider">
        <Swiper
          className="p-0 m-0 mb-2 swiper-wrapper list-inline"
          slidesPerView={1}
          spaceBetween={32}
          modules={[Navigation]}
          navigation={{
            nextEl: "#testimonial-slider-next",
            prevEl: "#testimonial-slider-prev",
          }}
          breakpoints={{
            320: { slidesPerView: 1 },
            550: { slidesPerView: 1 },
            991: { slidesPerView: 1 },
            1400: { slidesPerView: 1 },
            1500: { slidesPerView: 1 },
            1920: { slidesPerView: 1 },
            2040: { slidesPerView: 1 },
            2440: { slidesPerView: 1 },
          }}
        >
          <SwiperSlide>
            <Testiominal
              testText="Torquatos nec eu, detr periculis ex, nihil expetendis in mei. Mei an pericula euripidis.hinc partem ei est. Eos ei nisl graecis, vix aperiri consequat an. Eius lorem tincidunt vix at, vel pertinax sensibus id, error epicurei mea. Mea facilisis urbanitas.Torquatos nec eu, detr periculis ex, nihil expetendis in mei."
              testOwner="Elsa Schmidt"
              testSubtitle="Spa"
              testImage={image24} />
          </SwiperSlide>
          <SwiperSlide>
            <Testiominal
              testText="Torquatos nec eu, detr periculis ex, nihil expetendis in mei. Mei an pericula euripidis.hinc partem ei est. Eos ei nisl graecis, vix aperiri consequat an. Eius lorem tincidunt vix at, vel pertinax sensibus id, error epicurei mea. Mea facilisis urbanitas.Torquatos nec eu, detr periculis ex, nihil expetendis in mei."
              testOwner="Leslie Alexander"
              testSubtitle="Hair stylist"
              testImage={image25} />
          </SwiperSlide>
          <SwiperSlide>
            <Testiominal
              testText="Torquatos nec eu, detr periculis ex, nihil expetendis in mei. Mei an pericula euripidis.hinc partem ei est. Eos ei nisl graecis, vix aperiri consequat an. Eius lorem tincidunt vix at, vel pertinax sensibus id, error epicurei mea. Mea facilisis urbanitas.Torquatos nec eu, detr periculis ex, nihil expetendis in mei."
              testOwner="Robert Fox"
              testSubtitle="Trainer"
              testImage={image26} />
          </SwiperSlide>
          <SwiperSlide>
            <Testiominal
              testText="Torquatos nec eu, detr periculis ex, nihil expetendis in mei. Mei an pericula euripidis.hinc partem ei est. Eos ei nisl graecis, vix aperiri consequat an. Eius lorem tincidunt vix at, vel pertinax sensibus id, error epicurei mea. Mea facilisis urbanitas.Torquatos nec eu, detr periculis ex, nihil expetendis in mei."
              testOwner="Leslie Alexander"
              testSubtitle="Hair Stylist"
              testImage={image25} />
          </SwiperSlide>
          <SwiperSlide>
            <Testiominal
              testText="Torquatos nec eu, detr periculis ex, nihil expetendis in mei. Mei an pericula euripidis.hinc partem ei est. Eos ei nisl graecis, vix aperiri consequat an. Eius lorem tincidunt vix at, vel pertinax sensibus id, error epicurei mea. Mea facilisis urbanitas.Torquatos nec eu, detr periculis ex, nihil expetendis in mei."
              testOwner="Robert Fox"
              testSubtitle="Trainer"
              testImage={image24} />
          </SwiperSlide>
        </Swiper>
        <div
          className="swiper-button swiper-button-next"
          id="testimonial-slider-next"
        ></div>
        <div
          className="swiper-button swiper-button-prev"
          id="testimonial-slider-prev"
        ></div>
      </div>
    </Row>
  </Container>
</div>;
const metrics = <div className="section-padding bg-body">
  <Container>
    <Row>
      <Col className="col-md-12 text-center">
        <div className="mb-2 text-uppercase text-primary sub-title">Build better & Faster</div>
        <h2 className="text-secondary mb-5 text-capitalize">Make something<br />
          <span className="text-primary">great </span>
          Out of nothing
        </h2>
      </Col>
    </Row>
    <Row className="align-items-center text-center row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 mt-5">
      <Col className="border-end">
        <h2 className=" mb-3 counter text-primary">
          <CountUp
            start={0}
            end={3}
            duration={10}
            useEasing={true}
            separator="," />
        </h2>
        <h6 className="mb-0">Best Designer Award</h6>
      </Col>
      <Col className="col mb-lg-0 mb-4 border-end">
        <h2 className=" mb-3 counter text-primary">
          <CountUp
            start={0}
            end={50}
            duration={10}
            useEasing={true}
            separator="," />
          +
        </h2>
        <h6 className="mb-0">Loyal Clients</h6>
      </Col>
      <Col className="border-end">
        <h2 className=" mb-3 counter text-primary">
          <CountUp
            start={0}
            end={158}
            duration={10}
            useEasing={true}
            separator="," />
          +
        </h2>
        <h6 className="mb-0">Nominee Awards</h6>
      </Col>
      <Col>
        <h2 className=" mb-3 counter text-primary">
          <CountUp
            start={0}
            end={92}
            duration={10}
            useEasing={true}
            separator="," />
          +
        </h2>
        <h6 className="mb-0">CSS Designs</h6>
      </Col>
    </Row>
  </Container>
</div>;
const  blog = <div className="section-card-padding">
  <Container>
    <Row className="align-items-center">
      <Col md={12} className="text-center">
        <p className="mb-2 text-uppercase text-primary sub-title">Blog</p>
        <h2 className="text-secondary mb-5 text-capitalize">
          All the <span className="text-primary">Support you Need</span>
        </h2>
      </Col>
      <div
        className="overflow-hidden slider-circle-btn mt-5 app-slider swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
        id="app-slider"
      >
        <Swiper
          className="p-0 m-0 swiper-wrapper list-inline"
          id="swiper-wrapper-ece6bc7df0d2e1b6"
          slidesPerView={3}
          spaceBetween={32}
          modules={[Navigation]}
          navigation={{
            nextEl: "#app-slider-next",
            prevEl: "#app-slider-prev",
          }}
          breakpoints={{
            320: { slidesPerView: 1 },
            550: { slidesPerView: 2 },
            991: { slidesPerView: 3 },
            1400: { slidesPerView: 3 },
            1500: { slidesPerView: 3 },
            1920: { slidesPerView: 4 },
            2040: { slidesPerView: 4 },
            2440: { slidesPerView: 4 },
          }}
        >
          <SwiperSlide className="card-slide mb-0 overflow-hidden">
            <BlogWidget
              blogImage={image13}
              blogTitle="The Expierience: Travel Tales From India and Abroad."
              blogDate="August 16 ,2023"
              Id="1" />
          </SwiperSlide>
          <SwiperSlide className="card-slide mb-0 overflow-hidden">
            <BlogWidget
              blogImage={image14}
              blogTitle="The Expierience: Travel Tales From India and Abroad."
              blogDate="August 17 ,2023"
              Id="2" />
          </SwiperSlide>
          <SwiperSlide className="card-slide mb-0 overflow-hidden">
            <BlogWidget
              blogImage={image15}
              blogTitle="The Expierience: Travel Tales From India and Abroad."
              blogDate="August 18 ,2023"
              Id="3" />
          </SwiperSlide>
          <SwiperSlide className="card-slide mb-0 overflow-hidden">
            <BlogWidget
              blogImage={image13}
              blogTitle="The Expierience: Travel Tales From India and Abroad."
              blogDate="August 19 ,2023"
              Id="4" />
          </SwiperSlide>
          <SwiperSlide className="card-slide mb-0 overflow-hidden">
            <BlogWidget
              blogImage={image14}
              blogTitle="The Expierience: Travel Tales From India and Abroad."
              blogDate="August 20 ,2023"
              Id="5" />
          </SwiperSlide>
          <SwiperSlide className="card-slide mb-0 overflow-hidden">
            <BlogWidget
              blogImage={image15}
              blogTitle="The Expierience: Travel Tales From India and Abroad."
              blogDate="August 21 ,2023"
              Id="6" />
          </SwiperSlide>
        </Swiper>
        <div
          className="swiper-button swiper-button-next"
          tabindex="0"
          id="app-slider-prev"
          role="button"
          aria-label="Next slide"
          aria-controls="swiper-wrapper-ece6bc7df0d2e1b6"
          aria-disabled="false"
        ></div>
        <div
          className="swiper-button swiper-button-prev"
          tabindex="0"
          id="app-slider-prev"
          role="button"
          aria-label="Previous slide"
          aria-controls="swiper-wrapper-ece6bc7df0d2e1b6"
          aria-disabled="false"
        ></div>
      </div>
    </Row>
  </Container>
</div>;
const team = <div className="section-card-padding bg-body">
  <Container>
    <Row className="align-items-center">
      <Col md={12} className="text-center">
        <div className="mb-2 text-uppercase text-primary sub-title">team</div>
        <h2 className="text-secondary mb-5 text-capitalize">
          We are a team of dedicated
          <br /> <span className="text-primary">Professionals</span>
        </h2>
        <p className="mb-5">It is a long established fact that a reader will be distracted by the readable
          content<br /> of a page when looking at its layout.
        </p>
      </Col>

    </Row>
    <Row className="row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 pt-5">
      <Col>
        <Card className="team-image">
          <Team
            teamImage={image9}
            teamTitle="Darlene Robertson"
            teamText="Founder" />
        </Card>
      </Col>
      <Col>
        <Card className="team-image">
          <Team
            teamImage={image10}
            teamTitle="Floyd Miles"
            teamText="UI designer" />
        </Card>
      </Col>
      <Col>
        <Card className="team-image">
          <Team
            teamImage={image11}
            teamTitle="Arlene McCoy"
            teamText="Researcher" />
        </Card>
      </Col>
      <Col>
        <Card className="team-image">
          <Team
            teamImage={image12}
            teamTitle="Darlene Robertson"
            teamText="Founder" />
        </Card>
      </Col>
    </Row>
  </Container>
</div>;
const appScreens = <div className="inner-box overflow-hidden">
  <div className="container-fluid">
    <Row>
      <div className="col-md-12 text-center">
        <div className="mb-2 text-uppercase text-primary sub-title">
          App Screenshots
        </div>
        <h2 className="text-secondary mb-4 mb-lg-5 text-capitalize">
          Seamless User Experience, Assured!
        </h2>
        <p className="mb-4 mb-lg-5 pb-4 pb-lg-5">
          It is specially designed for any kind of mobile app, software,
          sass, startup, <br /> marketing, one page and other online
          businesses.{" "}
        </p>
      </div>
    </Row>
    <Row className=" align-items-center justify-content-center">
      <div className="col-lg-2 col-md-6">
        <img
          src={image6}
          alt="10"
          className="img-fluid d-block mx-auto"
          style={{ filter: "invert(0)" }} />
      </div>
      <div className="col-lg-2 col-md-6">
        <img
          src={image7}
          alt="11"
          className="img-fluid d-block mx-auto"
          style={{ filter: "invert(0)" }} />
      </div>
      <div className="col-lg-3 col-md-12">
        <img
          src={image8}
          alt="12"
          className="img-fluid d-block mx-auto"
          style={{ filter: "invert(0)" }} />
      </div>
      <div className="col-lg-2 col-md-6">
        <img
          src={image22}
          alt="13"
          className="img-fluid d-block mx-auto"
          style={{ filter: "invert(0)" }} />
      </div>
      <div className="col-lg-2 col-md-6">
        <img
          src={image23}
          alt="14"
          className="img-fluid d-block mx-auto"
          style={{ filter: "invert(0)" }} />
      </div>
    </Row>
  </div>
</div>;
const AboveTheFold = <div className="bg-secondary features-card">
  <Container>
    <Row className="mx-2 mx-sm-0">
      <Col lg={6}></Col>
      <Col lg={6} className="top-feature">
        <div className="text-right">
          <p className="mb-2 text-uppercase sub-title text-white">
            Why Pangea Social rocks
          </p>
          <h2 className="mb-15 text-white notch-feature-txt mb-5 text-capitalize">
            Top Notch Features{" "}
          </h2>
          <p className="mb-0">
            Experience a better social media experience powered by socratic guardrail AI and micro payment rewards.{" "}
          </p>
        </div>
      </Col>
    </Row>
    <Row className="row-cols-sm-2 row-cols-lg-4">
      <Col>
        <Card className="services-box  rounded-2">
          <Card.Body className="card-body p-0">
            <h5 className="mb-3">Better<br/> Content</h5>
            <p>
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.{" "} */}
             Automatically identifies the most open-minded content and areas which might need improvement.
            </p>
            <svg
              className="text-primary"
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.45109 0.343108L5.46396 1.36387L10.0063 1.42104L0.0568434 11.3704L0.787737 12.1013L10.7371 2.15194L10.7943 6.6942L11.8151 6.70706L11.736 0.422159L5.45109 0.343108Z"
                fill="currentColor" />
            </svg>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="services-box  rounded-2">
          <Card.Body className="card-body p-0">
            <h5 className="mb-3">
              Better
              <br /> Conversations
            </h5>
            <p>
              Automatically identifies, analyzes and ranks all of the most fruitful content and conversations.{" "}
            </p>
            <svg
              className="text-primary"
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.45109 0.343108L5.46396 1.36387L10.0063 1.42104L0.0568434 11.3704L0.787737 12.1013L10.7371 2.15194L10.7943 6.6942L11.8151 6.70706L11.736 0.422159L5.45109 0.343108Z"
                fill="currentColor" />
            </svg>
          </Card.Body>
        </Card>
      </Col>
      <Col>
        <Card className="services-box  rounded-2">
          <Card.Body className="card-body p-0">
            <h5 className="mb-3">Members Only Rewards</h5>
            <p>
              Registered and premium members earn rewards from any published content, comments and likes.{" "}
            </p>
            <svg
              className="text-primary"
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.45109 0.343108L5.46396 1.36387L10.0063 1.42104L0.0568434 11.3704L0.787737 12.1013L10.7371 2.15194L10.7943 6.6942L11.8151 6.70706L11.736 0.422159L5.45109 0.343108Z"
                fill="currentColor" />
            </svg>
          </Card.Body>
        </Card>
      </Col>
      <Col className="mb-4">
        <Card className="services-box  rounded-1">
          <Card.Body className="card-body p-0">
            <h5 className="mb-3">
              Moderation AI 
              <br />
            </h5>
            <p>
            Patented AI, automatically analyzes content for open mindedness. Generates disincentives & guardrails for unhelpful content in community with the AI powered editor. {" "}
            </p>
            <svg
              className="text-primary"
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.45109 0.343108L5.46396 1.36387L10.0063 1.42104L0.0568434 11.3704L0.787737 12.1013L10.7371 2.15194L10.7943 6.6942L11.8151 6.70706L11.736 0.422159L5.45109 0.343108Z"
                fill="currentColor" />
            </svg>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
</div>;
const AboutUs = <div className="section-padding">
  <Container>
    <Row className="align-items-center">
      <Col className="md-6">
        <img src={image1} alt="" className="img-fluid " />
      </Col>
      <Col className="md-6">
        <div className="mb-2 text-primary text-uppercase sub-title">
          about us
        </div>
        <h2 className="text-secondary mb-lg-5 mb-4 text-capitalize">
          What They Say <br />
          <span className="text-primary">About Us</span>
        </h2>
        <p className="mb-4">
          It is a long established fact that a reader will be distracted
          by the readable content of a page when looking at its layout.{" "}
        </p>
        <div className="d-flex align-items-center mb-lg-5 mb-4 about-icon-box">
          <div className="p-2 border border-secondary rounded d-flex text-secondary align-items-center">
            <svg
              className=""
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5625 9.00008L7.6875 11.1251L11.9375 6.87508M15.8333 9.00008C15.8333 12.9121 12.662 16.0834 8.75 16.0834C4.83798 16.0834 1.66666 12.9121 1.66666 9.00008C1.66666 5.08806 4.83798 1.91675 8.75 1.91675C12.662 1.91675 15.8333 5.08806 15.8333 9.00008Z"
                stroke="#001F4C"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <span className="mb-0 ms-1">400+ UI Components</span>
          </div>
          <div className="p-2 border border-secondary rounded d-flex text-secondary align-items-center ms-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M5.5625 9.00008L7.6875 11.1251L11.9375 6.87508M15.8333 9.00008C15.8333 12.9121 12.662 16.0834 8.75 16.0834C4.83798 16.0834 1.66666 12.9121 1.66666 9.00008C1.66666 5.08806 4.83798 1.91675 8.75 1.91675C12.662 1.91675 15.8333 5.08806 15.8333 9.00008Z"
                stroke="#001F4C"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round" />
            </svg>
            <span className="mb-0 ms-1">92+ CSS Designs</span>
          </div>
        </div>
        <Link to="/auth/sign-up" className="btn btn-primary mt-2">
          Get Started
        </Link>
      </Col>
    </Row>
  </Container>
</div>;
const hero = <div className="inner-card-box bg-body">
  <Container>
    <Row>
      <Col
        lg={7}
        className="col-lg-7 banner-one-img text-center ms-2 ms-sm-0"
      >
        <img src={image} alt="img" className="img-fluid " />
      </Col>
      <Col lg={5} className="inner-box">
        <div className="mb-2 text-uppercase text-primary sub-title">
          Post content, earn rewards
        </div>
        <h1 className="text-secondary mb-lg-5 mb-4 text-capitalize">
          The Way Social Media
          {' '}
          <span className="text-primary">Should be</span>
        </h1>
        <p className="mb-lg-5 mb-4">
          Experience a better social media experience powered by socratic guardrail AI and micro payment rewards.{" "}
        </p>
        <div className="d-flex align-items-center store-btn pt-2">
          <Link className="btn btn-primary" to="#">
            Sign Up for Invite Launch
          </Link>
          <Link className="btn btn-secondary ms-4" to="#">
            Learn about our Crowdfunding
          </Link>
        </div>
        {/* <div className="star-box mt-4">
      <div className="d-flex mb-2  align-items-center">
        <div className="border-end pe-2">
          <div className="d-flex align-items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_1150_5773)">
                <path
                  d="M7.63067 1.28789C7.76731 0.959376 8.23272 0.959376 8.36936 1.28789L10.0222 5.26187C10.0798 5.40037 10.2101 5.495 10.3596 5.50698L14.6498 5.85093C15.0045 5.87936 15.1483 6.32197 14.8781 6.55343L11.6094 9.35344C11.4954 9.45104 11.4457 9.60416 11.4805 9.75L12.4791 13.9366C12.5617 14.2826 12.1852 14.5562 11.8815 14.3707L8.20848 12.1273C8.08048 12.0491 7.91951 12.0491 7.7915 12.1273L4.11845 14.3707C3.81481 14.5562 3.43831 14.2826 3.52087 13.9366L4.51951 9.75C4.55431 9.60416 4.50456 9.45104 4.39065 9.35344L1.12193 6.55343C0.851714 6.32197 0.995529 5.87936 1.35019 5.85093L5.64043 5.50698C5.78995 5.495 5.92019 5.40037 5.9778 5.26187L7.63067 1.28789Z"
                  fill="#FDB022"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_1150_5773">
                  <rect width="16" height="16" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
            <svg
              className="ms-1"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_1150_5772)">
                <path
                  d="M7.63067 1.28789C7.76731 0.959376 8.23272 0.959376 8.36936 1.28789L10.0222 5.26187C10.0798 5.40037 10.2101 5.495 10.3596 5.50698L14.6498 5.85093C15.0045 5.87936 15.1483 6.32197 14.8781 6.55343L11.6094 9.35344C11.4954 9.45104 11.4457 9.60416 11.4805 9.75L12.4791 13.9366C12.5617 14.2826 12.1852 14.5562 11.8815 14.3707L8.20848 12.1273C8.08048 12.0491 7.91951 12.0491 7.7915 12.1273L4.11845 14.3707C3.81481 14.5562 3.43831 14.2826 3.52087 13.9366L4.51951 9.75C4.55431 9.60416 4.50456 9.45104 4.39065 9.35344L1.12193 6.55343C0.851714 6.32197 0.995529 5.87936 1.35019 5.85093L5.64043 5.50698C5.78995 5.495 5.92019 5.40037 5.9778 5.26187L7.63067 1.28789Z"
                  fill="#FDB022"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_1150_5772">
                  <rect width="16" height="16" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
            <svg
              className="ms-1"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_1150_5778)">
                <path
                  d="M7.63067 1.28789C7.76731 0.959376 8.23272 0.959376 8.36936 1.28789L10.0222 5.26187C10.0798 5.40037 10.2101 5.495 10.3596 5.50698L14.6498 5.85093C15.0045 5.87936 15.1483 6.32197 14.8781 6.55343L11.6094 9.35344C11.4954 9.45104 11.4457 9.60416 11.4805 9.75L12.4791 13.9366C12.5617 14.2826 12.1852 14.5562 11.8815 14.3707L8.20848 12.1273C8.08048 12.0491 7.91951 12.0491 7.7915 12.1273L4.11845 14.3707C3.81481 14.5562 3.43831 14.2826 3.52087 13.9366L4.51951 9.75C4.55431 9.60416 4.50456 9.45104 4.39065 9.35344L1.12193 6.55343C0.851714 6.32197 0.995529 5.87936 1.35019 5.85093L5.64043 5.50698C5.78995 5.495 5.92019 5.40037 5.9778 5.26187L7.63067 1.28789Z"
                  fill="#FDB022"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_1150_5778">
                  <rect width="16" height="16" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
            <svg
              className="ms-1"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <g clip-path="url(#clip0_1150_5779)">
                <path
                  d="M7.63067 1.28789C7.76731 0.959376 8.23272 0.959376 8.36936 1.28789L10.0222 5.26187C10.0798 5.40037 10.2101 5.495 10.3596 5.50698L14.6498 5.85093C15.0045 5.87936 15.1483 6.32197 14.8781 6.55343L11.6094 9.35344C11.4954 9.45104 11.4457 9.60416 11.4805 9.75L12.4791 13.9366C12.5617 14.2826 12.1852 14.5562 11.8815 14.3707L8.20848 12.1273C8.08048 12.0491 7.91951 12.0491 7.7915 12.1273L4.11845 14.3707C3.81481 14.5562 3.43831 14.2826 3.52087 13.9366L4.51951 9.75C4.55431 9.60416 4.50456 9.45104 4.39065 9.35344L1.12193 6.55343C0.851714 6.32197 0.995529 5.87936 1.35019 5.85093L5.64043 5.50698C5.78995 5.495 5.92019 5.40037 5.9778 5.26187L7.63067 1.28789Z"
                  fill="#FDB022"
                ></path>
              </g>
              <defs>
                <clipPath id="clip0_1150_5779">
                  <rect width="16" height="16" fill="white"></rect>
                </clipPath>
              </defs>
            </svg>
            <svg
              className="ms-1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M9.65623 2.03398C9.80257 1.58357 10.4398 1.58358 10.5861 2.03398L12.1443 6.82956C12.2097 7.03099 12.3974 7.16737 12.6092 7.16737H17.6516C18.1252 7.16737 18.3221 7.77338 17.939 8.05174L13.8596 11.0156C13.6883 11.1401 13.6166 11.3607 13.682 11.5622L15.2402 16.3577C15.3865 16.8081 14.871 17.1827 14.4879 16.9043L10.4085 13.9405C10.2372 13.816 10.0052 13.816 9.83382 13.9405L5.75445 16.9043C5.37131 17.1827 4.85581 16.8081 5.00215 16.3577L6.56033 11.5622C6.62578 11.3607 6.55408 11.1401 6.38274 11.0156L2.30337 8.05174C1.92024 7.77338 2.11714 7.16737 2.59072 7.16737H7.6331C7.84489 7.16737 8.0326 7.03099 8.09805 6.82956L9.65623 2.03398Z"
                fill="#D9D9D9"
              ></path>
              <mask
                id="mask0_1182_914"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="2"
                y="1"
                width="17"
                height="16"
              >
                <path
                  d="M9.65623 2.03398C9.80257 1.58357 10.4398 1.58358 10.5861 2.03398L12.1443 6.82956C12.2097 7.03099 12.3974 7.16737 12.6092 7.16737H17.6516C18.1252 7.16737 18.3221 7.77338 17.939 8.05174L13.8596 11.0156C13.6883 11.1401 13.6166 11.3607 13.682 11.5622L15.2402 16.3577C15.3865 16.8081 14.871 17.1827 14.4879 16.9043L10.4085 13.9405C10.2372 13.816 10.0052 13.816 9.83382 13.9405L5.75445 16.9043C5.37131 17.1827 4.85581 16.8081 5.00215 16.3577L6.56033 11.5622C6.62578 11.3607 6.55408 11.1401 6.38274 11.0156L2.30337 8.05174C1.92024 7.77338 2.11714 7.16737 2.59072 7.16737H7.6331C7.84489 7.16737 8.0326 7.03099 8.09805 6.82956L9.65623 2.03398Z"
                  fill="#F2B827"
                ></path>
              </mask>
              <g mask="url(#mask0_1182_914)">
                <rect
                  x="-1"
                  y="0.603027"
                  width="13"
                  height="19"
                  fill="#F2B827"
                ></rect>
              </g>
            </svg>
          </div>
        </div>
        <img
          src={image21}
          alt="02"
          className="img-fluid ps-2 star-icon"
        />
      </div>
    <h6 className="mb-0 mt-1 text-black">
        <span>500k+</span> Downloads
      </h6>
    </div> */}
      </Col>
    </Row>
  </Container>
</div>;
const cta = <div className="section-padding bg-body">
  <Container>
    <Row className="align-items-center">
      <Col lg={5}>
        <div className="mb-2 text-primary text-uppercase sub-title">
          Sign Up now
        </div>
        <h2 className="text-secondary mb-lg-5 mb-4 text-capitalize">
          Fast, easy, and <br />{" "}
          <span className="text-primary">Affordable</span>
        </h2>
        <p className="mb-lg-5 mb-4">
          It is a long established fact that a reader will be distracted
          by the readable content of a page when looking at its layout.{" "}
        </p>
        <div className="d-flex align-items-center store-btn flex-wrap pt-2">
          <img src={image2} alt="04" className="img-fluid" />
          <img src={image3} alt="05" className="img-fluid ms-3" />
        </div>
        <div className="star-box mt-4">
          <div className="d-flex mb-2  align-items-center">
            <div className="border-end pe-2">
              <div className="d-flex align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1150_5774)">
                    <path
                      d="M7.63067 1.28789C7.76731 0.959376 8.23272 0.959376 8.36936 1.28789L10.0222 5.26187C10.0798 5.40037 10.2101 5.495 10.3596 5.50698L14.6498 5.85093C15.0045 5.87936 15.1483 6.32197 14.8781 6.55343L11.6094 9.35344C11.4954 9.45104 11.4457 9.60416 11.4805 9.75L12.4791 13.9366C12.5617 14.2826 12.1852 14.5562 11.8815 14.3707L8.20848 12.1273C8.08048 12.0491 7.91951 12.0491 7.7915 12.1273L4.11845 14.3707C3.81481 14.5562 3.43831 14.2826 3.52087 13.9366L4.51951 9.75C4.55431 9.60416 4.50456 9.45104 4.39065 9.35344L1.12193 6.55343C0.851714 6.32197 0.995529 5.87936 1.35019 5.85093L5.64043 5.50698C5.78995 5.495 5.92019 5.40037 5.9778 5.26187L7.63067 1.28789Z"
                      fill="#FDB022"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1150_5774">
                      <rect width="16" height="16" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
                <svg
                  className="ms-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1150_5776)">
                    <path
                      d="M7.63067 1.28789C7.76731 0.959376 8.23272 0.959376 8.36936 1.28789L10.0222 5.26187C10.0798 5.40037 10.2101 5.495 10.3596 5.50698L14.6498 5.85093C15.0045 5.87936 15.1483 6.32197 14.8781 6.55343L11.6094 9.35344C11.4954 9.45104 11.4457 9.60416 11.4805 9.75L12.4791 13.9366C12.5617 14.2826 12.1852 14.5562 11.8815 14.3707L8.20848 12.1273C8.08048 12.0491 7.91951 12.0491 7.7915 12.1273L4.11845 14.3707C3.81481 14.5562 3.43831 14.2826 3.52087 13.9366L4.51951 9.75C4.55431 9.60416 4.50456 9.45104 4.39065 9.35344L1.12193 6.55343C0.851714 6.32197 0.995529 5.87936 1.35019 5.85093L5.64043 5.50698C5.78995 5.495 5.92019 5.40037 5.9778 5.26187L7.63067 1.28789Z"
                      fill="#FDB022"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1150_5776">
                      <rect width="16" height="16" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
                <svg
                  className="ms-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1150_5775)">
                    <path
                      d="M7.63067 1.28789C7.76731 0.959376 8.23272 0.959376 8.36936 1.28789L10.0222 5.26187C10.0798 5.40037 10.2101 5.495 10.3596 5.50698L14.6498 5.85093C15.0045 5.87936 15.1483 6.32197 14.8781 6.55343L11.6094 9.35344C11.4954 9.45104 11.4457 9.60416 11.4805 9.75L12.4791 13.9366C12.5617 14.2826 12.1852 14.5562 11.8815 14.3707L8.20848 12.1273C8.08048 12.0491 7.91951 12.0491 7.7915 12.1273L4.11845 14.3707C3.81481 14.5562 3.43831 14.2826 3.52087 13.9366L4.51951 9.75C4.55431 9.60416 4.50456 9.45104 4.39065 9.35344L1.12193 6.55343C0.851714 6.32197 0.995529 5.87936 1.35019 5.85093L5.64043 5.50698C5.78995 5.495 5.92019 5.40037 5.9778 5.26187L7.63067 1.28789Z"
                      fill="#FDB022"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1150_5775">
                      <rect width="16" height="16" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
                <svg
                  className="ms-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1150_5780)">
                    <path
                      d="M7.63067 1.28789C7.76731 0.959376 8.23272 0.959376 8.36936 1.28789L10.0222 5.26187C10.0798 5.40037 10.2101 5.495 10.3596 5.50698L14.6498 5.85093C15.0045 5.87936 15.1483 6.32197 14.8781 6.55343L11.6094 9.35344C11.4954 9.45104 11.4457 9.60416 11.4805 9.75L12.4791 13.9366C12.5617 14.2826 12.1852 14.5562 11.8815 14.3707L8.20848 12.1273C8.08048 12.0491 7.91951 12.0491 7.7915 12.1273L4.11845 14.3707C3.81481 14.5562 3.43831 14.2826 3.52087 13.9366L4.51951 9.75C4.55431 9.60416 4.50456 9.45104 4.39065 9.35344L1.12193 6.55343C0.851714 6.32197 0.995529 5.87936 1.35019 5.85093L5.64043 5.50698C5.78995 5.495 5.92019 5.40037 5.9778 5.26187L7.63067 1.28789Z"
                      fill="#FDB022"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_1150_5780">
                      <rect width="16" height="16" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
                <svg
                  className="ms-1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M9.65623 2.03398C9.80257 1.58357 10.4398 1.58358 10.5861 2.03398L12.1443 6.82956C12.2097 7.03099 12.3974 7.16737 12.6092 7.16737H17.6516C18.1252 7.16737 18.3221 7.77338 17.939 8.05174L13.8596 11.0156C13.6883 11.1401 13.6166 11.3607 13.682 11.5622L15.2402 16.3577C15.3865 16.8081 14.871 17.1827 14.4879 16.9043L10.4085 13.9405C10.2372 13.816 10.0052 13.816 9.83382 13.9405L5.75445 16.9043C5.37131 17.1827 4.85581 16.8081 5.00215 16.3577L6.56033 11.5622C6.62578 11.3607 6.55408 11.1401 6.38274 11.0156L2.30337 8.05174C1.92024 7.77338 2.11714 7.16737 2.59072 7.16737H7.6331C7.84489 7.16737 8.0326 7.03099 8.09805 6.82956L9.65623 2.03398Z"
                    fill="#D9D9D9"
                  ></path>
                  <mask
                    id="mask0_1182_915"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="2"
                    y="1"
                    width="17"
                    height="16"
                  >
                    <path
                      d="M9.65623 2.03398C9.80257 1.58357 10.4398 1.58358 10.5861 2.03398L12.1443 6.82956C12.2097 7.03099 12.3974 7.16737 12.6092 7.16737H17.6516C18.1252 7.16737 18.3221 7.77338 17.939 8.05174L13.8596 11.0156C13.6883 11.1401 13.6166 11.3607 13.682 11.5622L15.2402 16.3577C15.3865 16.8081 14.871 17.1827 14.4879 16.9043L10.4085 13.9405C10.2372 13.816 10.0052 13.816 9.83382 13.9405L5.75445 16.9043C5.37131 17.1827 4.85581 16.8081 5.00215 16.3577L6.56033 11.5622C6.62578 11.3607 6.55408 11.1401 6.38274 11.0156L2.30337 8.05174C1.92024 7.77338 2.11714 7.16737 2.59072 7.16737H7.6331C7.84489 7.16737 8.0326 7.03099 8.09805 6.82956L9.65623 2.03398Z"
                      fill="#F2B827"
                    ></path>
                  </mask>
                  <g mask="url(#mask0_1182_915)">
                    <rect
                      x="-1"
                      y="0.603027"
                      width="13"
                      height="19"
                      fill="#F2B827"
                    ></rect>
                  </g>
                </svg>
              </div>
            </div>
            <img
              src={image21}
              alt="02"
              className="img-fluid ps-2 star-icon" />
          </div>
          <h6 className="mb-0 mt-1 text-black">
            <span>50k+</span> Members
          </h6>
        </div>
      </Col>
      <Col lg={7} className="mt-lg-0 mt-5">
        <img src={image4} alt="" className="img-fluid d-block mx-auto" />
      </Col>
    </Row>
  </Container>
</div>;
const pricing = (
  <div className="section-card-padding">
    <Container>
      <Row className="align-items-center">
        <Col lg={12}>
          <p className="mb-2 text-primary text-uppercase sub-title">Pricing</p>
          <h2 className="text-secondary mb-lg-5 mb-4 text-capitalize">
          Membership  {" "}
            <span className="text-primary">
              
               Options
            </span>
          </h2>
          <p className="mb-lg-5 mb-4">
            Experience a better social media experience powered by socratic {' '}
            guardrail AI and micro payment rewards. <Link href="/dashboard" className="btn btn-primary mt-2">
            View Demo
          </Link>
          </p>
         
        </Col>
        <Col lg={8} className="col-lg-12 mt-4 mt-lg-0">
          <Row className="iq-star-inserted row-cols-1 row-cols-md-2 row-cols-lg-3 text-center">
            <Col className="col iq-star-inserted-3">
              <Card className="shadow-lg">
                <Card.Body className="my-2">
                  <h5 className="my-0 fw-normal mb-4">Free</h5>
                  <div className="d-flex justify-content-center align-items-baseline  mb-4">
                    <h4 className="card-title pricing-card-title mb-0 text-primary">
                      $0{" "}
                    </h4>
                    <h6 className="mb-0"> / month</h6>
                  </div>

                  <ul className="list-unstyled my-3 p-0">
                    <li className="mb-4">
                      <p className="text-black mb-0">Totally Free</p>{" "}
                    </li>
                    <li className="mb-4">
                      <p className="text-black mb-0">Lifetime Free Account</p>{" "}
                    </li>

                    {/* <li> &nbsp;</li> */}
                    <li className="mb-4">
                      Free One-time 100 Points credit on us
                  </li>
                      {/* 
                    <li className="mb-4">
                      Freely Read up to 5 posts or articles a month
                    </li> */}
                    <li className="mb-4">
                      Freely Read Unlimited posts or articles a month
                    </li>
                    {/* <li> &nbsp;</li> */}
                    <li className="mb-4">
                      Buy additional Points at a 2x higher dollar price than
                      Premium ($1.00 = 5000 Pangea Points)
                    </li>

                    {/* <li> &nbsp;</li> */}
                    <li>
                      <p>Earn Rewards with some limitations**</p>
                    </li>
                    <li className="mb-4">Basic Analytics, Reports and Tools</li>

                    <li>
                      <p>No Ads Ever!</p>
                    </li>

                    <li className="mb-0">Feedback & Support Portal</li>
                    <li className="mb-4">Lifetime free account</li>

                    <li className="mb-4">
                      You are never the product on Pangea! Your posts, comments, points and
                      earnings are yours!
                    </li>
                  </ul>
                  
                  <Link to="/auth/sign-up">  
                  <button type="button" className="btn btn-primary w-100">
                    Get Started
                  </button>
                  </Link>

                </Card.Body>
              </Card>
            </Col>

            <Col className="iq-star-inserted-3">
              <Card className="my-5">
                <Card.Body className="page-bg">
                  <h5 className="my-0 fw-normal mb-4">Premium</h5>
                  <div className="d-flex justify-content-center align-items-baseline  mb-4">
                    <h4 className="card-title pricing-card-title mb-0 text-primary">
                      $5
                    </h4>
                    <h6 className="mb-0"> / month</h6>
                  </div>
                  
                  <p className="text-black mb-4">$60 Billed Yearly</p>
                  <p className="text-green ">
                    $6.50 Billed Monthly ($75 over 12 months)
                  </p>
                  <ul className="list-unstyled my-3 p-0">
                    <li className="mb-4">
                    40,000 Points credit included (4/5ths)
                    </li>
                    <li className="mb-4">
                      Additional Points 1x dollar price ($1.00 = 10,000 Pangea
                      Points) or upgrade plan +
                    </li>

                    <li><p>Everything with FREE Plus:</p></li>
                    
                    {/* <li className="mb-4">Basic Analytics, Reports and Tools</li> */}
                    <li>
                      <p>Earn Rewards with NO limitations **</p>
                    </li>

                    {/* <li className="mb-3">Help center access</li> */}
                    {/* <li>Help center access</li> */}

                    {/* <li>
                      <p>No Ads Ever!</p>
                    </li>

                    <li className="mb-4">Feedback & Support Portal</li>
                    <li className="mb-4">Lifetime free account</li>

                    <li className="mb-4">
                      You are never the product on Pangea! Your posts, comments, points and
                      earnings are yours!
                    </li> */}
                    
                    <li><p>Perfect for individual power users & long term community members.</p></li>
                  </ul>
                  <Link to="/auth/sign-up"> 
                    <button
                      type="button"
                      className="btn custom-btn btn-white w-100 shadow"
                    >
                      Get Started
                    </button>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col className="iq-star-inserted-3">
              <Card className="my-5">
                <Card.Body className="page-bg">
                  <h5 className="my-0 fw-normal mb-4">Premium+</h5>
                  <div className="d-flex justify-content-center align-items-baseline  mb-4">
                    <h4 className="card-title pricing-card-title mb-0 text-primary">
                      $50
                    </h4>
                    <h6 className="mb-0"> / user / month</h6>
                  </div>
                  <p className="text-black mb-4">$600 Billed Yearly</p>
                  <p className="text-green">
                    $62.50 Billed Monthly ($750 over 12 months)
                  </p>
                  <ul className="list-unstyled my-3 p-0">
                    <li className="mb-4">
                      Monthly 400,000 Points credit included (4/5ths)
                    </li>
                    
                     
                    <li className="mb-4">
                      Additional Points at normal 0.75x dollar price ($1.00
                      = 14,000 Pangea Points)
                    </li>

                    <li><p>Everything with Premium Plus:</p></li>
                    <li className="mb-4">
                      Advanced Analytics, Reports and Tools
                    </li>

                    <li>
                      <p>
                        Earn Rewards with HIGHER REWARDS than Premium (staking
                        higher points on posts and comments for higher ROI) **
                      </p>
                    </li>
                    <li><p>Perfect for influencers, startups, businesses, brands & enterprises.</p></li>
                    {/* <li className="mb-3">Help center access</li> */}
                    {/* <li>Help center access</li> */}

                    {/* <li>
                      <p>No Ads Ever!</p>
                    </li>

                    <li className="mb-4">Feedback & Support Portal</li>
                    <li className="mb-4">Lifetime free account</li>

                    <li className="mb-4">
                      You are never the product on Pangea!
                    Your posts, comments, points and
                      earnings are yours!
                    </li> */}
                  </ul>
                  
                  <Link to="/auth/sign-up"> 
                    <button
                      type="button"
                      className="btn custom-btn btn-white w-100 shadow"
                    >
                      Get Started
                    </button>
                  </Link>
{/*                   
                  <button
                    type="button"
                    className="btn custom-btn btn-white w-100 shadow"
                  >
                    Get Started
                  </button> */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </div>
);
const Home = memo(() => {
  return (
    <Fragment>
      {hero}
      {AboveTheFold}
      {/* {AboutUs} */}
      {/* {cta} */}
      {pricing}
      {/* {appScreens} */}

      {/* {team} */}

      {/* { blog} */}

      {/* {metrics} */}

      {/* {logoMarquee} */}

      {/* {testimonials} */}
      {/* {extraContentPangea} */}
    </Fragment>
  );
});

Home.displayName = "Home";
export default Home;
