import React,{useState,memo,Fragment} from 'react'

//React-bootstrap
import {Image,Collapse} from 'react-bootstrap'

//Router
import {Link} from 'react-router-dom'

//Component
import Commentfromcontrol from './comment-from-control'

import Likeshare from './like-share'

import  { Component } from 'react';

import {updatePostConnections, jsPlumb} from '../../social/pages/social-profile' //'../../social/components/social-profile.js'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };

    if (this.props.showError === false) {
      this.state.error = null;
      this.state.errorInfo = null;
    }
  }

  componentDidCatch = (error, info) => {
    console.log("error did catch");
    this.setState({ error: error, errorInfo: info });
  };

  render() {
    if (this.state.errorInfo) {
      return (
        <div
          style={{
            backgroundColor: "#ffcc99",
            color: "white",
            width: "500px",
            height: "60px",
          }}
        >
          An Error Occurred !
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}


const Friendcomment = memo((props) => {
    const [open, setOpen] = useState(false);
    var noLikes = 0;
    var noShares = 0;
    var noComments = 0;
    var netPoints = 0;
    var id = "comment-1";
    // var updatePostConnections = props.updatePostConnections || false
    var postData= null;
    if (props.postData) {
        postData = props.postData;
        if (props.postData.noShares) noShares = props.postData.noShares
        if (props.postData.noLikes) noLikes =  props.postData.noLikes
        if (props.postData.noComments) noComments = props.postData.noComments
        
    }
    if (props.netPoints) netPoints = props.netPoints
    if (props.pid) id = props.pid; 

     
    var netPointsColorCSS = "black" 
    
    if (netPoints>0) netPointsColorCSS = "success"
    // if (netPoints==0) netPointsColorCSS = "black"
    if (netPoints<0) netPointsColorCSS = "danger"

    function commentReply(e) {
      e.preventDefault();
      setOpen(!open); 
      // updatePostConnections(); 
      return false; 
    }
    return (
        <Fragment >
            {/* <ErrorBoundary> */}
            <div className="d-flex"  cid={id}>
                <Image id={id} src={props.userImage} alt="userimg" className="avatar-50 rounded-circle me-3 img-fluid"/>
                <div className="w-100">
                    <h6 className="mb-1 user text-primary">{props.userName} <span> {props.commentDuration} </span></h6>
                    <p className="mb-1">{props.userComment}</p>
                    <div className="d-flex flex-wrap align-items-center gap-3">
                          
                        <Link to="/" onClick={(e) => e.preventDefault() } >Like</Link>
                        <Link to="#"   onClick={(
                          e
                          // , postData
                        ) => {
                          // commentReply(e)
                          
      e.preventDefault();
      setOpen(!open); 
      // updatePostConnections(postData); 

      try {
        // Ensure postData is available in this scope
        // if (postData) {
          if (1==1) {
          
          // console.log('postData is  defined - updatePostConnections');
          // updatePostConnections(postData);
          jsPlumb.repaintEverything();
        } else {
          // console.error('postData is not defined');
        }
      } catch (error) {
        console.error('Failed to update post connections:', error);
      }
      return false;
                        
                        }} aria-controls="example-collapse-text" aria-expanded={open}>Reply</Link>
                        <span id="valuation" > 
                       
                       <span class={`badge rounded-pill text-bg-white fw-lightb text-${netPointsColorCSS} border border-${netPointsColorCSS}`}> 🌎 $ {netPoints}</span>
                   </span>
                 <Likeshare 
                        noLikes={noLikes} 
                        noComments={noComments} 
                        noShare={noShares} 
                        netPoints={netPoints}
                    /> 
                    {/* <hr/> */}
                    {/* &nbsp; */}
                    </div>
                    <Collapse in={open}>
                        <div>
                            <Commentfromcontrol placeholder="Enter Your Reply..."/>
                        </div>
                    </Collapse>
                </div>
            </div>
            {/* </ErrorBoundary> */}
        </Fragment>
    )
})

Friendcomment.displayName="Friendcomment"
export default Friendcomment