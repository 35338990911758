import React, { StrictMode , Component} from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

//router
import { RouterProvider, createBrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Index from "./views/index";

// import Dashboard from "./views/dashboard/index.js";
import { LandingModulesRouter } from "./views/modules/landing-pages/router/landing-modules-router";

//store

import { Provider } from "react-redux";
//reducer
import { store } from "./store";
import { IndexRouters } from "./router";


// import { ErrorBoundary } from "./ErrorBoundary";

import LandingPage, {LandingPageStatic} from './LandingPage'; // Adjust the import path as necessary

// Import the JSON data
import data from './content/data2.json';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };

    if (this.props.showError === false) {
      this.state.error = null;
      this.state.errorInfo = null;
    }
  }

  componentDidCatch = (error, info) => {
    console.log("error did catch");
    this.setState({ error: error, errorInfo: info });
  };

  render() {
    if (this.state.errorInfo) {
      return (
        <div
          style={{
            backgroundColor: "#ffcc99",
            color: "white",
            width: "500px",
            height: "60px",
          }}
        >
          An Error Occurred !
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
const router = createBrowserRouter(
  [
    {
      path: "/",
      // element: <Index />,
      // element: <Navigate to="/social/newsfeed" replace />
      // element: <LandingPage data={data}/>,
      element: <LandingPageStatic data={data}/>,

      // redirect: '/dashboard',
    },
    
    {
      path: "/template",
      // element: <Index />,
      // element: <Navigate to="/social/newsfeed" replace />
      element: <LandingPage data={data}/>,
      // element: <LandingPageStatic data={data}/>,

      // redirect: '/dashboard',
    },
    
    {
      path: "/design-guidelines",
      // element: <Index />,
      // element: <Navigate to="/social/newsfeed" replace />
      element: <Index />,

      // redirect: '/dashboard',
    },
    {
      path: "/learnmore",
      // element: <Index />, 
      element: <Navigate to="/landing-pages/home" replace /> 

      // redirect: '/dashboard',
    },
    {
      path: "/why",
      // element: <Index />,
      element: <Navigate to="/landing-pages/software-landing-page" />

      // redirect: '/dashboard',
    },
    
    {
      path: "/feed",
      // element: <Index />,
      // element: <Navigate to="/social/newsfeed" replace />
      element: <Navigate to="/social/social-profile" />

      // redirect: '/dashboard',
    },
    
    {
      path: "/logout",
      // element: <Index />,
      // element: <Navigate to="/social/newsfeed" replace />
      element: <Navigate to="/auth/sign-in" />

      // redirect: '/dashboard',
    },
    ...IndexRouters,
    ...LandingModulesRouter,
  ],
  { basename: process.env.PUBLIC_URL }
);
ReactDOM.createRoot(document.getElementById("root")).render(
  <StrictMode>
  <ErrorBoundary>
    <Provider store={store}>
      
      <App>
        <RouterProvider router={router}></RouterProvider>
       
      </App>
    </Provider>
        </ErrorBoundary>
  </StrictMode>
);

// Task: Create a JavaScript function that listens for the Ctrl+E keystroke and clears the 'hideme' CSS class definition in memory.


// // Function to clear the 'hideme' CSS class definition
// function clearCSSClassDefinition1(className) {
//   // Loop through all stylesheets
//   for (let sheet of document.styleSheets) {
//     try {
//       // Check if the stylesheet is accessible
//       if (sheet.cssRules) {
//         // Loop through all CSS rules in the stylesheet
//         for (let i = sheet.cssRules.length - 1; i >= 0; i--) {
//           let rule = sheet.cssRules[i];
//           // If the rule is a CSSStyleRule and the selector matches the class
//           if (rule.type === CSSRule.STYLE_RULE && rule.selectorText === `.${className}`) {
//             // Delete the rule
//             sheet.deleteRule(i);
//             // Log the removal
//             console.log(`Removed CSS class definition: .${className}`);
//           }
//         }
//       }
//     } catch (e) {
//       // Log the error for debugging purposes
//       console.error('Error accessing stylesheet rules:', e);
//     }
//   }
// }
// Function to clear the 'hideme' CSS class definition
function clearCSSClassDefinition(className) {
  // Loop through all stylesheets
  for (let sheet of document.styleSheets) {
    try {
      // Check if the stylesheet is accessible
      if (sheet.cssRules) {
        // Loop through all CSS rules in the stylesheet
        for (let i = sheet.cssRules.length - 1; i >= 0; i--) {
          let rule = sheet.cssRules[i];
          // If the rule is a CSSStyleRule and the selector matches the class
          if (rule.type === CSSRule.STYLE_RULE && rule.selectorText === `.${className}`) {
            // Delete the rule
            sheet.deleteRule(i);
            // Log the removal
            console.log(`Removed CSS class definition: .${className}`);
          }
        }
      }
    } catch (e) {
      // Log the error for debugging purposes
      console.error('Error accessing stylesheet rules:', e);
      // Handle the specific error for CSSRule access
      if (e instanceof DOMException && e.name === 'SecurityError') {
        console.warn(`Cannot access rules for stylesheet: ${sheet.href || '[inline styles]'}`);
      }
    }
  }
}

// // Function to untoggle the 'hideme' class from all elements and log information about them
// function untoggleHideMeClass() {
//   // Select all elements with the 'hideme' class
//   const elements = document.querySelectorAll('.hideme');
//   // Log the number of elements found
//   console.log(`Found ${elements.length} elements with the 'hideme' class.`);
//   // Loop through the NodeList
//   elements.forEach((element, index) => {
//     // Log details about the element
//     console.log(`Element ${index + 1}:`, element);
//     // Remove the 'hideme' class from the element
//     element.classList.remove('hideme');
//   });
//   // Log the action
//   console.log('All elements with the \'hideme\' class have been made visible.');
// }

// // Function to handle the keydown event
// function handleKeydownEvent(event) {
//   // Check if Ctrl+E is pressed
//   if (event.ctrlKey && event.key === 'e') {
//     // Prevent default action to avoid any browser shortcut conflict
//     event.preventDefault();
//     // Clear the 'hideme' CSS class definition
//     // clearCSSClassDefinition('hideme');?

//     // Untoggle the 'hideme' class from all elements
//     untoggleHideMeClass();
//     console.log('Ctrl+E pressed and hidden or future elements made visible');
//   }
// }
// Function to toggle between 'hideme' and 'hidemeoff' classes for all elements
function toggleHideMeClass() {
  // Select all elements with the 'hideme' or 'hidemeoff' class
  const elementsWithHideMe = document.querySelectorAll('.hideme');
  const elementsWithHideMeOff = document.querySelectorAll('.hidemeoff');
  
  // Toggle the classes for elements with 'hideme'
  elementsWithHideMe.forEach(element => {
    element.classList.remove('hideme');
    element.classList.add('hidemeoff');
  });

  // Toggle the classes for elements with 'hidemeoff'
  elementsWithHideMeOff.forEach(element => {
    element.classList.remove('hidemeoff');
    element.classList.add('hideme');
  });

  // Log the action
  console.log(`Toggled classes: 'hideme' to 'hidemeoff' and vice versa.`);
}

// State variable to track the first key press
let firstKeyPressDetected = false;
// Timeout to reset the first key press
let keyPressTimeout;

// Function to handle the keydown event for double press detection
function handleKeydownEvent(event) {
  // Check if Ctrl+E is pressed
  if (event.ctrlKey && event.key === 'e') {
    // Prevent default action to avoid any browser shortcut conflict
    event.preventDefault();
    // Check if this is the first key press
    if (!firstKeyPressDetected) {
      // Set the flag to true
      firstKeyPressDetected = true;
      // Log the first key press
      console.log('Ctrl+E pressed once. Press again to toggle the hideme class.');
      // Set a timeout to reset the flag after 1 second
      clearTimeout(keyPressTimeout);
      keyPressTimeout = setTimeout(() => {
        firstKeyPressDetected = false;
      }, 1000); // Reset after 1 second
    } else {
      // If the second press is detected within 1 second, toggle the classes
      toggleHideMeClass();
      // Reset the flag
      firstKeyPressDetected = false;
      // Clear the timeout
      clearTimeout(keyPressTimeout);
      console.log('Ctrl+E pressed twice. hideme class toggled.');
    }
  }
}
// Attach the keydown event listener to the document
document.addEventListener('keydown', handleKeydownEvent);

// TODO: Ensure that the 'hideme' class is not being used in inline styles as this method will not clear those.

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
