import React, { memo, Fragment, useEffect } from "react";

// react-router
import { Outlet } from "react-router-dom";

// headers
import Header1 from "../../../modules/landing-pages/components/partials/header/header-1";
import Header2 from "../../../modules/landing-pages/components/partials/header/header-2";

// footers
import Footer from "../../../modules/landing-pages/components/partials/footer/footer";
import Footer1 from "../../../modules/landing-pages/components/partials/footer/footer-one";

// scss
import "../../../../assets/modules/landing-pages/scss/landing-pages.scss";
import { Button } from "react-bootstrap";

const DefaultLayout = memo((props) => {
  // useEffect(() => {
  //   const backToTop = document.getElementById("back-to-top");
  //   if (backToTop !== null && backToTop !== undefined) {
  //     document
  //       .getElementById("back-to-top")
  //       .classList.add("animate__animated", "animate__fadeOut");
  //     window.addEventListener("scroll", (e) => {
  //       if (document.documentElement.scrollTop > 250) {
  //         document
  //           .getElementById("back-to-top")
  //           .classList.remove("animate__fadeOut");
  //         document
  //           .getElementById("back-to-top")
  //           .classList.add("animate__fadeIn");
  //       } else {
  //         document
  //           .getElementById("back-to-top")
  //           .classList.remove("animate__fadeIn");
  //         document
  //           .getElementById("back-to-top")
  //           .classList.add("animate__fadeOut");
  //       }
  //     });
  //   }
  //   document.body.classList.add("landing-pages");
  //   document.documentElement.classList.add("landing-pages");
  //   document.body.classList.add("body-bg");
  //   return () => {
  //     document.body.classList.remove("landing-pages");
  //     document.documentElement.classList.remove("landing-pages");
  //     document.body.classList.remove("body-bg");
  //   };
  // });

  useEffect(() => {
    // Ensure the DOM is fully loaded before trying to access elements
    if (document.readyState === "loading") {
      document.addEventListener("DOMContentLoaded", afterDOMLoaded);
    } else {
      afterDOMLoaded();
    }
  
    function afterDOMLoaded() {
      const backToTop = document.getElementById("back-to-top");
      if (backToTop) {
        window.addEventListener("scroll", handleScroll);
      }
  
      document.body.classList.add("landing-pages", "body-bg");
      document.documentElement.classList.add("landing-pages");
  
      return () => {
        window.removeEventListener("scroll", handleScroll);
        document.body.classList.remove("landing-pages", "body-bg");
        document.documentElement.classList.remove("landing-pages");
      };
    }
  
    function handleScroll() {
      const backToTop = document.getElementById("back-to-top");
      if (document.documentElement.scrollTop > 250) {
        backToTop.classList.remove("animate__fadeOut");
        backToTop.classList.add("animate__fadeIn");
      } else {
        backToTop.classList.remove("animate__fadeIn");
        backToTop.classList.add("animate__fadeOut");
      }
    }
  });
  return (
    <Fragment>
      <main className="main-content">
        <div className="position-relative">
          {/* <!--Nav Start--> */}
          {props.header1 === "true" && <Header1 />}
          {props.header2 === "true" && <Header2 />}
          {/* <!--Nav End--> */}
        </div>
        <Outlet />
      </main>
      {/* <!--Nav Start--> */}
      {props.footer1 ? <Footer1 /> : <Footer />}
      {/* <!--Nav End--> */}

      <a
        className="btn btn-fixed-end btn-primary  btn-landing"
        href="/auth/sign-up"
        target="_blank"
      >
        View Dashboard
      </a>
      {/* {{!-- Back-To-Top --}} */}
      <div id="back-to-top" style={{ display: "none" }}>
        <Button size="xs" variant="primary  p-0 position-fixed top" href="#top">
          <svg
            className="icon-30"
            width="30"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 15.5L12 8.5L19 15.5"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
        </Button>
      </div>
      {/* {{!-- Back-To-end --}} */}
    </Fragment>
  );
});

DefaultLayout.displayName = "DefaultLayout";
export default DefaultLayout;
