import React from 'react'

// default layout
import Default from '../layouts/dashboard/default';

// Dashboard
import Index from '../views/dashboard/index'
import AlternateDashboard from '../views/dashboard/alternate-dashboard'

// icon pages
import Solid from '../views/dashboard/icons/solid';
import Outline from '../views/dashboard/icons/outline';
import DualTone from '../views/dashboard/icons/dual-tone';

// special pages
import Billing from '../views/dashboard/special-pages/billing';
import Calender from '../views/dashboard/special-pages/calender';
import Kanban from '../views/dashboard/special-pages/kanban';
import Pricing from '../views/dashboard/special-pages/pricing';
import Timeline from '../views/dashboard/special-pages/timeline';

// user pages
import Userprofile from '../views/dashboard/app/user-profile';
import UserAdd from '../views/dashboard/app/user-add';
import UserList from '../views/dashboard/app/user-list';

// extra
import Admin from '../views/dashboard/admin/admin';
import Uicolor from '../views/modules/plugins/pages/ui-color';

// widget
import Widgetbasic from '../views/dashboard/widget/widgetbasic';
import Widgetchart from '../views/dashboard/widget/widgetchart';
import Widgetcard from '../views/dashboard/widget/widgetcard';

// map
import Google from '../views/dashboard/maps/google';

// form
import FormElement from '../views/dashboard/from/form-element';
import FormWizard from '../views/dashboard/from/form-wizard';
import FormValidation from '../views/dashboard/from/form-validation';

// table
import BootstrapTable from '../views/dashboard/table/bootstrap-table';
import TableData from '../views/dashboard/table/table-data';
import Borderedtable from '../views/dashboard/table/border-table';
import Fancytable from '../views/dashboard/table/fancy-table';
import FixedTable from '../views/dashboard/table/fixed-table';

//extra 
import PrivacyPolicy from '../views/dashboard/extra/privacy-policy'
import TermsofService from '../views/dashboard/extra/terms-of-service'
import BlankPage from '../views/dashboard/extra/blank-page'
import ShimmerComponent from '../views/dashboard/extra/ShimmerComponent'
import Vector from '../views/dashboard/maps/vector';

export const DefaultRouter = [
    {
        path: "/",
        element: <Default />,
        children:[
            {
            path: "dashboard",
            element: <Index />
            },
            {
                path: "/dashboard/alternate-dashboard",
                element: <AlternateDashboard />
            },
            // Special Pages
            {
                path: 'dashboard/special-pages/billing',
                element:<Billing/>
            },
            {
                path: 'dashboard/special-pages/calender',
                element:<Calender/>
            },
            {
                path: 'dashboard/special-pages/kanban',
                element:<Kanban/>
            },
            {
                path: 'dashboard/special-pages/pricing',
                element:<Pricing/>
            },
            {
                path: 'dashboard/special-pages/timeline',
                element:<Timeline/>
            },
            //User 
            {
                path: 'dashboard/app/user-profile',
                element:<Userprofile/>
            },
            {
                path:'dashboard/app/user-add',
                element:<UserAdd/>
            },
            {
                path: 'dashboard/app/user-list',
                element:<UserList/>
            },
            // Admin
            {
                path: 'dashboard/admin/admin',
                element:<Admin/>
            },
            // Ui-Color
            {
                path: 'plugins/ui-color',
                element:<Uicolor/>
            },
            // Widgets
            {
                path: 'dashboard/widget/widgetbasic',
                element:<Widgetbasic/>
            },
            {
                path: 'dashboard/widget/widgetchart',
                element:<Widgetchart/>
            },
            {
                path: 'dashboard/widget/widgetcard',
                element:<Widgetcard/>
            },
            // Map
            {
                path:'dashboard/map/google',
                element:<Google/>
            },
            {
                path:'dashboard/map/vector',
                element:<Vector/>
            },
            // Form
            {
                path: 'dashboard/form/form-element',
                element:<FormElement/>
            },
            {
                path: 'dashboard/form/form-wizard',
                element:<FormWizard/>
            },
            {
                path: 'dashboard/form/form-validation',
                element:<FormValidation/>
            },
            // Table
            {
                path: 'dashboard/table/bootstrap-table',
                element:<BootstrapTable/>
            },
            {
                path: 'dashboard/table/table-data',
                element:<TableData/>
            },
            {
                path: 'dashboard/table/border-table',
                element:<Borderedtable/>
            },
            {
                path: 'dashboard/table/fancy-table',
                element:<Fancytable/>
            },
            {
                path: 'dashboard/table/fixed-table',
                element:<FixedTable/>
            },
            
            // Icon
            {
                path: 'dashboard/icon/solid',
                element:<Solid/>
            },
            {
                path: 'dashboard/icon/outline',
                element: <Outline />
            },
            {
                path: 'dashboard/icon/dual-tone',
                element: <DualTone/>
            },
            // extra-pages
            {
                path: 'dashboard/extra/privacy-policy',
                element: <PrivacyPolicy/>
            },
            {
                path: 'dashboard/extra/terms-of-service',
                element: <TermsofService/>
            },
            {
                path: 'dashboard/extra/blank-page',
                element: <BlankPage/>
            },
            {
                path: 'dashboard/extra/ShimmerComponent',
                element: <ShimmerComponent/>
            },
        ]
    },
]
